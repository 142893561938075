import React from 'react';
import './List.css';

function List({ children }) {
  return (
    <>
      {children}
    </>
  )
}

export default List;